import React from 'react'

const HomeService = () => {
    return (
        <>
            <section className="blog-area">
                <div className="container">
                    <div className="blog-area-inner section-spacing">
                        <div className="section-header wow fadeInUp" data-wow-delay=".3s">
                            <div className="section-title-wrapper">
                                <div className="subtitle-wrapper">
                                    <span className="section-subtitle">Services</span>
                                </div>
                                <div className="title-wrapper">
                                    <h2 className="section-title" style={{fontSize:"30px"}}>We Offer A Wide Range Of Services</h2>
                                </div>
                            </div>
                        </div>
                        <div className="blogs-wrapper-box">
                            <div className="blogs-wrapper">
                                <a to="">
                                    <article className="blog wow fadeInUp" data-wow-delay=".3s">
                                        <div className="thumb">
                                            <img src="assets/imgs/blog/blog-1.webp" alt="blog image" />
                                        </div>
                                        <div className="content-wrapper">
                                            <div className="content">
                                                <div className="meta">
                                                </div>
                                                
                                                <p style={{textAlign:"justify"}}>From conception to completion, our entire team of estimator, designers, project managers and experienced executive make our clients ‘ needs a priority. We have experience in a wide variety of projects and delivery methods, and use both time proven practices and cutting-edge techniques to make sure our customer’s projects meet their maximum potential.
                                                </p>
                                            </div>
                                        </div>
                                    </article>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default HomeService