import React from 'react'
import { Link } from "react-router-dom";
import HomeService from './HomeService';



const HomeAbout = () => {
    return (
        <>
            <section className="about-area">
                <div className="container">
                    <div className="about-area-inner section-spacing-top">
                        <div className="section-header wow fadeInUp" data-wow-delay=".3s">
                            <div className="section-title-wrapper">
                                <div className="subtitle-wrapper">
                                    <span className="section-subtitle">about us</span>
                                </div>
                                <div className="title-wrapper">
                                    <h2 className="section-title" style={{ fontSize: "32px" }}>We, build or remodel homes, commercial buildings, infrastructure and industrial projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="thumb wow fadeInLeft" data-wow-delay=".3s">
                                <img src="assets/imgs/gallery/gallery-1.webp" alt="gallery image" />
                            </div>
                            <div className="content-last wow fadeInRight" data-wow-delay=".5s">
                                <div className="text-wrapper">
                                    <p className="text" style={{ textAlign: "justify" }}>Akhilesh Pandey Construction Company brings over 19 years of local and national construction experience; with a reputation for delivering large and unique design-build projects ahead of schedule and on budget. Our company has served clients and building owners for over four decades. In that time, we have found that the key to every project’s success is understanding the owner’s needs and expectations and then exceeding those expectations .</p>
                                    {/* <p className="text">AI Construction is a trusted name in the construction industry with an established reputation for high quality standards, design uniqueness and transparency.</p> */}
                                </div>
                                <div className="feature-list">
                                    <ul>
                                        <li><i className="fa-solid fa-angles-right" />Over 19 Years of Experience
                                        </li>
                                        <li><i className="fa-solid fa-angles-right" />On-time Delivery of Projects
                                        </li>
                                        <li><i className="fa-solid fa-angles-right" />Clear & Transparent Pricing
                                        </li>
                                        <li><i className="fa-solid fa-angles-right" />Excellent Customer Support

                                        </li>
                                    </ul>
                                </div>
                                <div className="btn-wrapper">
                                    <Link to="/about-us" className="t-btn t-btn-primary btn-text-flip"> <span data-text="Learn More">Learn
                                        More</span><i className="fa-solid fa-arrow-right" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="counter-area">
                <div className="container">
                    <div className="counter-area-inner section-spacing-top">
                        <div className="counter-wrapper-box">
                            <div className="counter-wrapper">
                                <div className="counter-box  wow fadeInUp" data-wow-delay=".3s">
                                    <div className="icon">
                                        <img src="assets/imgs/icon/icon-7.webp" alt="icon" />
                                    </div>
                                    <div className="content">
                                        <h2 className="number t-counter">3k</h2>
                                        <p className="text">Happy Clients</p>
                                    </div>
                                </div>
                                <div className="counter-box  wow fadeInUp" data-wow-delay=".5s">
                                    <div className="icon">
                                        <img src="assets/imgs/icon/icon-8.webp" alt="icon" />
                                    </div>
                                    <div className="content">
                                        <h2 className="number t-counter">2k</h2>
                                        <p className="text">Complete Project</p>
                                    </div>
                                </div>
                                <div className="counter-box  wow fadeInUp" data-wow-delay=".7s">
                                    <div className="icon">
                                        <img src="assets/imgs/icon/icon-9.webp" alt="icon" />
                                    </div>
                                    <div className="content">
                                        <h2 className="number t-counter">75+</h2>
                                        <p className="text">Team Member</p>
                                    </div>
                                </div>
                                <div className="counter-box  wow fadeInUp" data-wow-delay=".9s">
                                    <div className="icon">
                                        <img src="assets/imgs/icon/icon-10.webp" alt="icon" />
                                    </div>
                                    <div className="content">
                                        <h2 className="number t-counter">12+</h2>
                                        <p className="text">Winning Award</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <HomeService />

            <section className="faq-area">
        <div className="container">
          <div className="faq-area-inner section-spacing">
            <div className="section-content wow fadeInLeft" data-wow-delay=".3s">
              <div className="section-title-wrapper">
                <div className="title-wrapper">
                  <h2 className="section-title" style={{ fontSize: "37px" }}>Akhilesh Pandey (Director)</h2>
                </div>
              </div>
              <div className="accordion-faq">
                <div className="accordion accordion-flush" id="accordionFlushExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                        Over 19 Years of Experience
                      </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body" style={{ textAlign: "" }}>We specialize in Civil & Structural Building Works, Residential Construction,Building Contractor, Interior & Furnishing works and also, our sheer interest lies in developing commercial and residential spaces at an affordable price without compromising on the quality.
                        We Have been into this business for more than 19 Years and over this passage of time, we keep up Delivering Quality & Excellence in our Works.
                        Ours is a team of highly professional individuals who believe quality is fundamental and time is precious.
                        The objective of the company is to bring the best construction techniques & technical innovations to our projects.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="faq-thumb wow fadeInRight" data-wow-delay=".5s">
              <img src="assets/imgs/gallery/Director.jpeg" alt="image" />
            </div>
          </div>
        </div>
      </section>



      <section className="faq-area">
        <div className="container">
          <div className="faq-area-inner section-spacing">

          <div className="faq-thumb wow fadeInRight" data-wow-delay=".5s">
              <img src="assets/imgs/gallery/CEO.jpeg" alt="image" />
            </div>
            <div className="section-content wow fadeInLeft" data-wow-delay=".3s">
              <div className="section-title-wrapper">
                <div className="title-wrapper">
                  <h2 className="section-title" style={{ fontSize: "37px" }}>Indrabhan Pandey (CEO)</h2>
                </div>
              </div>
              <div className="accordion-faq">
                <div className="accordion accordion-flush" id="accordionFlushExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                        Over 19 Years of Experience
                      </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body" style={{ textAlign: "" }}>We specialize in Civil & Structural Building Works, Residential Construction,Building Contractor, Interior & Furnishing works and also, our sheer interest lies in developing commercial and residential spaces at an affordable price without compromising on the quality.
                        We Have been into this business for more than 19 Years and over this passage of time, we keep up Delivering Quality & Excellence in our Works.
                        Ours is a team of highly professional individuals who believe quality is fundamental and time is precious.
                        The objective of the company is to bring the best construction techniques & technical innovations to our projects.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </section>

            <section className="service-area-2">
                <div className="container">
                    <div className="service-area-2-inner section-spacing-top">
                        <div className="services-wrapper-box mt-0">
                            <div className="services-wrapper">
                                <a to="service-details.html">
                                    <div className="service-box wow fadeInUp" data-wow-delay=".3s">
                                        <div className="icon">
                                            <img src="assets/imgs/icon/icon-13.webp" alt="service icon" />
                                        </div>
                                        <div className="content">
                                            <h3 className="title"><b> Our Values </b> </h3>
                                            <p className="text">

                                                <ul>
                                                    <li> <b>1.</b> To enhance the quality of constructions.</li>
                                                    <li>  <b>2.</b> To serve with best value for money and high return on project. </li>
                                                    <li>  <b>3.</b> To acquaint client with Residential and commercial Project of standards. </li>
                                                    <li>  <b>4.</b> Serve with an environment of professionalism ,team work and excellence . </li>
                                                    <li>   <b>5.</b> To respect all environment rules and regulation and legal requirements.</li>
                                                    <li> <b>6.</b> To enhance customers  </li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </a>
                                <a to="service-details.html">
                                    <div className="service-box wow fadeInUp" data-wow-delay=".5s">
                                        <div className="icon">
                                            <img src="assets/imgs/icon/icon-14.webp" alt="service icon" />
                                        </div>
                                        <div className="content">
                                            <h3 className="title"><b>Security & Safety</b> </h3>
                                            <p className="text">The manpower of Akhilesh Pandey  is its most valuable resource. To ensure t safe workplace for all stuffs and also clients ‘ investment and facilities, the group has evolved a strong health and safety culture driven by a goal to achieve “Zero accidents“ and “zero man-hours lost”. The safety culture has been instilled in ours all employees with the conviction  that every accident is preventable. The company fully acknowledges that by complying with stringent health, safety and Environment  procedure ,zero accidents can be accomplished.
                                            </p>
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HomeAbout