import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import { Link } from "react-router-dom";


export default function SliderTestimonials() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}

                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 1,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 1,
                        spaceBetween: 50,
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >


                <SwiperSlide>
                    <div className="swiper-slide">
                        <div className="testimonial-content">
                            <div className="icon">
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                            </div>
                            <div className="text-wrapper">
                                <p className="text">I recently contacted Abhishek from AI Construction regarding a lease property inquiry. The quality of his construction and design is exceptional. When I decide to build a house in the future, I know who to reach out to! Highly recommended. </p>
                            </div>
                            <div className="meta">
                                <h3 className="name">Yuvaraj Bk</h3>
                                {/* <span className="designation">Construction Engineer</span> */}
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-slide">
                        <div className="testimonial-content">
                            <div className="icon">
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                            </div>
                            <div className="text-wrapper">
                                <p className="text">I got to know the builder when we went house hunting for a rental property, we were introduced to his projects and it felt unreal the kind of efforts and precautions and precision that has been undertaken through all of them. I would definitely rate this company a 5 star any day. </p>
                            </div>
                            <div className="meta">
                                <h3 className="name">Lakshmi Ravi Kumar</h3>
                                {/* <span className="designation">Construction Engineer</span> */}
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-slide">
                        <div className="testimonial-content">
                            <div className="icon">
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                            </div>
                            <div className="text-wrapper">
                                <p className="text">I met the owner of the company during a house hunt wherein I understood the details of his company and the way they're conducting their projects. I'm pretty sure the work that they are doing suits the name extremely well. </p>
                            </div>
                            <div className="meta">
                                <h3 className="name">Yashpal Ravi</h3>
                                {/* <span className="designation">Construction Engineer</span> */}
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-slide">
                        <div className="testimonial-content">
                            <div className="icon">
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                                <i className="fa-solid fa-star" />
                            </div>
                            <div className="text-wrapper">
                                <p className="text">I recently contacted Abhishek from AI Construction regarding a lease property inquiry. The quality of his construction and design is exceptional. When I decide to build a house in the future, I know who to reach out to! Highly recommended. </p>
                            </div>
                            <div className="meta">
                                <h3 className="name">Reshma Haridas</h3>
                                {/* <span className="designation">Construction Engineer</span> */}
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}
