import React from 'react'
import { Link } from "react-router-dom";


const HomeTeamMember = () => {
    return (
        <>

            <section className="team-area">
                <div className="container">
                    <div className="team-area-inner section-spacing-top">
                        <div className="section-header wow fadeInUp" data-wow-delay=".3s">
                            <div className="section-title-wrapper">
                                <div className="subtitle-wrapper">
                                    <span className="section-subtitle"> Our Project</span>
                                </div>
                                <div className="title-wrapper">
                                    <h2 className="section-title">Our Project</h2>
                                </div>
                            </div>
                        </div>
                        <div className="team-wrapper-box">
                            <div className="team-wrapper">
                                <div className="team-box wow fadeInUp" data-wow-delay=".3s">
                                    <div className="thumb">
                                        <Link to="/project"><img src="assets/imgs/team/Picture4.jpg" alt="image" /></Link>
                                    </div>
                                </div>
                                <div className="team-box wow fadeInUp" data-wow-delay=".5s">
                                    <div className="thumb">
                                        <Link to="/project"><img src="assets/imgs/team/Picture3.jpg" alt="image" /></Link>
                                    </div>
                                </div>
                                <div className="team-box wow fadeInUp" data-wow-delay=".7s">
                                    <div className="thumb">
                                        <Link to="/project"><img src="assets/imgs/team/Picture2.jpg" alt="image" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HomeTeamMember