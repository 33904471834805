import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MainLayOut from "./component/MainLayOut";
import { useEffect } from "react";
import Home from "./pages/Home";
import Contact from "./pages/contact/Contact";
import Project from "./pages/project/Project";
import Services from "./pages/services/Services";
import Blog from "./pages/blog/Blog";
import AboutUs from "./pages/about/AboutUs";
import Gallery from "./pages/gallery/Gallery";


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/project" element={<Project />} />
            <Route path="/services" element={<Services />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact-us" element={<Contact />} />

          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
