import React from 'react'
import { Link } from "react-router-dom";


const Gallery = () => {
    return (
        <>
            <section className="page-title-area">
                <div className="container">
                    <div className="page-title-area-inner section-spacing">
                        <div className="area-shape-1">
                            <img src="assets/imgs/shape/Contact.png" alt="shape" />
                        </div>
                        <div className="section-content">
                            <div className="section-title-wrapper">
                                <div className="title-wrapper">
                                    <h1 className="section-title wow fadeInUp" data-wow-delay=".3s">Gallery</h1>
                                </div>
                            </div>
                            <ul className="breadcrumb wow fadeInUp" data-wow-delay=".5s">
                                <li><Link to="/">Home</Link></li>
                                <li>Gallery</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            <section className="project-area">
                <div className="container">
                    <div className="project-area-inner section-spacing-top">
                        <div className="projects-wrapper-box mt-0">
                            <div className="projects-wrapper">
                                <a className="large" to="project-details.html">
                                    <div className="project-box wow fadeInUp" data-wow-delay=".3s">
                                        <div className="thumb">
                                            <img src="assets/imgs/project/01.jpeg" alt="project image" />
                                        </div>
                                        {/* <div className="content">
                                            <h3 className="title">General Contracting</h3>
                                            <p className="text">The construction industry is awa cornerstone of economic development, driving
                                            </p>
                                        </div> */}
                                    </div>
                                </a>
                                <a to="project-details.html">
                                    <div className="project-box wow fadeInUp" data-wow-delay=".5s">
                                        <div className="thumb">
                                            <img src="assets/imgs/project/02.jpeg" alt="project image" />
                                        </div>
                                        {/* <div className="content">
                                            <h3 className="title">Electrical Installation</h3>
                                            <p className="text">The construction industry is awa cornerstone of economic development, driving
                                            </p>
                                        </div> */}
                                    </div>
                                </a>
                                <a to="project-details.html">
                                    <div className="project-box wow fadeInUp" data-wow-delay=".3s">
                                        <div className="thumb">
                                            <img src="assets/imgs/project/03.jpeg" alt="project image" />
                                        </div>
                                        {/* <div className="content">
                                            <h3 className="title">Flooring Installation</h3>
                                            <p className="text">The construction industry is awa cornerstone of economic development, driving
                                            </p>
                                        </div> */}
                                    </div>
                                </a>
                                <a className="large" to="project-details.html">
                                    <div className="project-box wow fadeInUp" data-wow-delay=".5s">
                                        <div className="thumb">
                                            <img src="assets/imgs/project/04.jpeg" alt="project image" />
                                        </div>
                                        {/* <div className="content">
                                            <h3 className="title">Landscaping </h3>
                                            <p className="text">The construction industry is awa cornerstone of economic development, driving
                                            </p>
                                        </div> */}
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="projects-wrapper-box mt-lg-5">
                            <div className="projects-wrapper">
                                <a className="large" to="project-details.html">
                                    <div className="project-box wow fadeInUp" data-wow-delay=".3s">
                                        <div className="thumb">
                                            <img src="assets/imgs/project/05.jpeg" alt="project image" />
                                        </div>
                                        {/* <div className="content">
                                            <h3 className="title">General Contracting</h3>
                                            <p className="text">The construction industry is awa cornerstone of economic development, driving
                                            </p>
                                        </div> */}
                                    </div>
                                </a>
                                <a to="project-details.html">
                                    <div className="project-box wow fadeInUp" data-wow-delay=".5s">
                                        <div className="thumb">
                                            <img src="assets/imgs/project/06.jpeg" alt="project image" />
                                        </div>
                                        {/* <div className="content">
                                            <h3 className="title">Electrical Installation</h3>
                                            <p className="text">The construction industry is awa cornerstone of economic development, driving
                                            </p>
                                        </div> */}
                                    </div>
                                </a>
                                <a to="project-details.html">
                                    <div className="project-box wow fadeInUp" data-wow-delay=".3s">
                                        <div className="thumb">
                                            <img src="assets/imgs/project/07.jpeg" alt="project image" />
                                        </div>
                                        {/* <div className="content">
                                            <h3 className="title">Flooring Installation</h3>
                                            <p className="text">The construction industry is awa cornerstone of economic development, driving
                                            </p>
                                        </div> */}
                                    </div>
                                </a>
                                <a className="large" to="project-details.html">
                                    <div className="project-box wow fadeInUp" data-wow-delay=".5s">
                                        <div className="thumb">
                                            <img src="assets/imgs/project/08.jpeg" alt="project image" />
                                        </div>
                                        {/* <div className="content">
                                            <h3 className="title">Landscaping </h3>
                                            <p className="text">The construction industry is awa cornerstone of economic development, driving
                                            </p>
                                        </div> */}
                                    </div>
                                </a>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="project-area">
                <div className="container">
                    <div className="project-area-inner section-spacing-top">
                        <div className="projects-wrapper-box mt-0">
                            <div className="projects-wrapper">
                                <a className="large" to="project-details.html">
                                    <div className="project-box wow fadeInUp" data-wow-delay=".3s">
                                        <div className="thumb">
                                            <img src="assets/imgs/project/09.jpeg" alt="project image" />
                                        </div>
                                        {/* <div className="content">
                                            <h3 className="title">General Contracting</h3>
                                            <p className="text">The construction industry is awa cornerstone of economic development, driving
                                            </p>
                                        </div> */}
                                    </div>
                                </a>
                                <a to="project-details.html">
                                    <div className="project-box wow fadeInUp" data-wow-delay=".5s">
                                        <div className="thumb">
                                            <img src="assets/imgs/project/10.jpeg" alt="project image" />
                                        </div>
                                        {/* <div className="content">
                                            <h3 className="title">Electrical Installation</h3>
                                            <p className="text">The construction industry is awa cornerstone of economic development, driving
                                            </p>
                                        </div> */}
                                    </div>
                                </a>
                                <a to="project-details.html">
                                    <div className="project-box wow fadeInUp" data-wow-delay=".3s">
                                        <div className="thumb">
                                            <img src="assets/imgs/project/11.jpeg" alt="project image" />
                                        </div>
                                        {/* <div className="content">
                                            <h3 className="title">Flooring Installation</h3>
                                            <p className="text">The construction industry is awa cornerstone of economic development, driving
                                            </p>
                                        </div> */}
                                    </div>
                                </a>
                                <a className="large" to="project-details.html">
                                    <div className="project-box wow fadeInUp" data-wow-delay=".5s">
                                        <div className="thumb">
                                            <img src="assets/imgs/project/12.jpeg" alt="project image" />
                                        </div>
                                        {/* <div className="content">
                                            <h3 className="title">Landscaping </h3>
                                            <p className="text">The construction industry is awa cornerstone of economic development, driving
                                            </p>
                                        </div> */}
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="projects-wrapper-box mt-lg-5">
                            <div className="projects-wrapper">
                                <a className="large" to="project-details.html">
                                    <div className="project-box wow fadeInUp" data-wow-delay=".3s">
                                        <div className="thumb">
                                            <img src="assets/imgs/project/13.jpeg" alt="project image" />
                                        </div>
                                        {/* <div className="content">
                                            <h3 className="title">General Contracting</h3>
                                            <p className="text">The construction industry is awa cornerstone of economic development, driving
                                            </p>
                                        </div> */}
                                    </div>
                                </a>
                                <a to="project-details.html">
                                    <div className="project-box wow fadeInUp" data-wow-delay=".5s">
                                        <div className="thumb">
                                            <img src="assets/imgs/project/14.jpeg" alt="project image" />
                                        </div>
                                        {/* <div className="content">
                                            <h3 className="title">Electrical Installation</h3>
                                            <p className="text">The construction industry is awa cornerstone of economic development, driving
                                            </p>
                                        </div> */}
                                    </div>
                                </a>
                                <a to="project-details.html">
                                    <div className="project-box wow fadeInUp" data-wow-delay=".3s">
                                        <div className="thumb">
                                            <img src="assets/imgs/project/15.jpeg" alt="project image" />
                                        </div>
                                        {/* <div className="content">
                                            <h3 className="title">Flooring Installation</h3>
                                            <p className="text">The construction industry is awa cornerstone of economic development, driving
                                            </p>
                                        </div> */}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Gallery