import React from 'react'
import { Link } from "react-router-dom";


const Blog = () => {
    return (
        <>
            <section className="page-title-area">
                <div className="container">
                    <div className="page-title-area-inner section-spacing">
                        <div className="area-shape-1">
                            <img src="assets/imgs/shape/Contact.png" alt="shape" />
                        </div>
                        <div className="section-content">
                            <div className="section-title-wrapper">
                                <div className="title-wrapper">
                                    <h1 className="section-title wow fadeInUp" data-wow-delay=".3s">Blog</h1>
                                </div>
                            </div>
                            <ul className="breadcrumb wow fadeInUp" data-wow-delay=".5s">
                                <li><Link to="/">Home</Link></li>
                                <li>Blog</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            

        </>
    )
}

export default Blog