import React from 'react'
import { Link } from "react-router-dom";
import SliderHomeBanner from '../../component/slidercomponent/SliderHomeBanner';


const HomeBanner = () => {
    return (
        <>

            <div class="has-smooth" id="has_smooth">
                <div id="smooth-wrapper">
                    <div id="smooth-content">
                        <div class="body-wrapper">
                            <section className="hero-area-2">
                                <div className="container">
                                    <div className="hero-area-2-inner">
                                        <div className="area-shape-1">
                                            <img src="assets/imgs/shape/shape-3.webp" alt="shape" />
                                        </div>
                                        <div className="section-content">
                                            <div className="section-title-wrapper">
                                                <div className="subtitle-wrapper">
                                                    <span className="section-subtitle wow fadeInUp" data-wow-delay=".3s">Best AI Construction</span>
                                                </div>
                                                <div className="title-wrapper">
                                                    <h1 className="section-title large wow fadeInUp" data-wow-delay=".5s"> We are the leading construction company in India</h1>
                                                    {/* <h1 className="section-title large wow fadeInUp" data-wow-delay=".5s"> We Craft Your Dreams to Life</h1> */}
                                                </div>
                                            </div>
                                            <div className="text-wrapper">
                                                <p className="text wow fadeInUp" data-wow-delay=".7s">The construction industry is awa cornerstone of
                                                    economic was constrin development
                                                </p>
                                            </div>
                                            <div className="btn-wrapper wow fadeInUp" data-wow-delay=".9s">
                                                <Link to="/about-us" className="t-btn t-btn-primary btn-text-flip"> <span data-text="Learn More">Learn
                                                    More</span><i className="fa-solid fa-arrow-right" /></Link>
                                                <Link to="/contact-us" className="t-btn t-btn-primary btn-text-flip bg-active"> <span data-text="Contact Now">Contact Now</span><i className="fa-solid fa-arrow-right" /></Link>
                                            </div>
                                        </div>
                                        <div className="hero-thumb wow fadeInUp" data-wow-delay=".1s">
                                            <img src="assets/imgs/shape/hero-shape-2.webp" alt="image" />
                                            <div className="mask-image">
                                                <img src="assets/imgs/gallery/gallery-11.webp" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeBanner