import React from 'react'
import { Link } from "react-router-dom";


const Services = () => {
    return (
        <>
            <section className="page-title-area">
                <div className="container">
                    <div className="page-title-area-inner section-spacing">
                        <div className="area-shape-1">
                            <img src="assets/imgs/shape/Contact.png" alt="shape" />
                        </div>
                        <div className="section-content">
                            <div className="section-title-wrapper">
                                <div className="title-wrapper">
                                    <h1 className="section-title wow fadeInUp" data-wow-delay=".3s">Services</h1>
                                </div>
                            </div>
                            <ul className="breadcrumb wow fadeInUp" data-wow-delay=".5s">
                                <li><Link to="/">Home</Link></li>
                                <li>Services</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blog-area">
                <div className="container">
                    <div className="blog-area-inner section-spacing">
                        <div className="section-header wow fadeInUp" data-wow-delay=".3s">
                            <div className="section-title-wrapper">
                                <div className="subtitle-wrapper">
                                    <span className="section-subtitle">Our Services</span>
                                </div>
                                <div className="title-wrapper">
                                    <h2 className="section-title" style={{fontSize:"29px"}}>We Offer A Wide Range Of Services</h2>
                                    <p style={{ textAlign: "justify" }} >From conception to completion, our entire team of estimator, designers, project managers and experienced executive make our clients ‘ needs a priority. We have experience in a wide variety of projects and delivery methods, and use both time proven practices and cutting-edge techniques to make sure our customer’s projects meet their maximum potential.</p>
                                </div>
                            </div>

                        </div>
                        <div className="blogs-wrapper-box">
                            <div className="blogs-wrapper">
                                <a to="blog-details.html">
                                    <article className="blog wow fadeInUp" data-wow-delay=".3s">
                                        <div className="thumb">
                                            <img src="assets/imgs/blog/Structural Construction.webp" alt="blog image" />
                                        </div>
                                        <div className="content-wrapper">
                                            <div className="content">
                                                <h2 className="title">Structural Construction
                                                </h2>
                                                <p style={{ textAlign: "justify" }}> As construction contractors, we stress upon durable and efficient structures that withstand the tide of time. Utilizing industry-wide best practices in structural contracting that include concreting, brickwork, formwork, and reinforcement work, our structures keep even decades old customers extremely satisfied. </p>
                                            </div>
                                        </div>
                                    </article>
                                </a>
                                <a to="blog-details.html">
                                    <article className="blog wow fadeInUp" data-wow-delay=".3s">
                                        <div className="thumb">
                                            <img src="assets/imgs/blog/Commercial Interiors.webp" alt="blog image" />
                                        </div>
                                        <div className="content-wrapper">
                                            <div className="content">
                                                <h2 className="title">Commercial Interiors
                                                </h2>
                                                <p style={{ textAlign: "justify" }}> Our team brings years of expertise in space utilization and glass facades that can make even the smallest of office locations look professional, aesthetically pleasing and comfortable. Be it traditional workstation offices, healthcare centers or art galleries, we have impressed one and all with our high-quality work. </p>
                                            </div>
                                        </div>
                                    </article>
                                </a>
                                <a to="blog-details.html">
                                    <article className="blog wow fadeInUp" data-wow-delay=".3s">
                                        <div className="thumb">
                                            <img src="assets/imgs/blog/Residential Interiors.jpg" alt="blog image" />
                                        </div>
                                        <div className="content-wrapper">
                                            <div className="content">
                                                <h2 className="title">Residential Interiors </h2>
                                                <p style={{ textAlign: "justify" }}> We believe the interiors of your homes tell a 1000 words even before any exchange of words takes place. With an innate desire to build beautiful homes, we identify ourselves primarily as residential contractors and then as a turnkey construction company. With a keen eye that caters to differing clients, our versatility in building contracting work including- flooring, painting, woodwork, and fittings- are sure to satisfy every individual’s tastes and preference. </p>
                                            </div>
                                        </div>
                                    </article>
                                </a>
                                <a to="blog-details.html">
                                    <article className="blog wow fadeInUp" data-wow-delay=".3s">
                                        <div className="thumb">
                                            <img src="assets/imgs/blog/Addition Alteration & Renovation.webp" alt="blog image" />
                                        </div>
                                        <div className="content-wrapper">
                                            <div className="content">
                                                <h2 className="title">Addition/Alteration & Renovation
                                                </h2>
                                                <p style={{ textAlign: "justify" }}> Is it structural/architectural alteration you need? Then look no further! At AI Construction we have a knack of bringing old buildings to life with our state of the art refurbishing practices. We especially take great pride in our ability to create alter spaces with structural steel members. We assure you with us by your side, your home renovation needs can never be any simpler! </p>
                                            </div>
                                        </div>
                                    </article>
                                </a>
                                <a to="blog-details.html">
                                    <article className="blog wow fadeInUp" data-wow-delay=".3s">
                                        <div className="thumb">
                                            <img src="assets/imgs/blog/Building Construction.webp" alt="blog image" />
                                        </div>
                                        <div className="content-wrapper">
                                            <div className="content">
                                                <h2 className="title">Building Construction | Home Remodeling | Project Management
                                                </h2>
                                                <p style={{ textAlign: "justify" }}> As in house contractors, our team comes with highly trained professionals, team leaders, and supervisors to meet all your requirements for a construction company. Our motto is to retain both in-house as well as extremely trained support staff/ building subcontractors to ensure high-grade end products for our customers.</p>
                                            </div>
                                        </div>
                                    </article>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default Services