import React from 'react'
import HomeBanner from './home/HomeBanner'
import HomeAbout from './home/HomeAbout'
import Testimonials from './home/Testimonials'
import HomeService from './home/HomeService'
import HomeTeamMember from './home/HomeTeamMember'

const Home = () => {
  return (
    <>
      <HomeBanner />
      <HomeAbout />
      <HomeTeamMember />
      <Testimonials />
      {/* <HomeService /> */}
     

    </>
  )
}

export default Home