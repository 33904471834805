import React from 'react'
import { Link } from "react-router-dom";
import WhatsappIcon from '../../pages/WhatsappIcon';


const Footer = () => {
  return (
    <>
      <footer className="footer-area">
        <div className="container">
          <div className="footer-widget-wrapper-box section-spacing-top">
            <div className="area-bg">
              <img src="./assets/imgs/shape/shape-1.webp" alt="image" />
            </div>
            <div className="footer-widget-wrapper">
              <div className="footer-widget-box">
                <div className="footer-logo">
                  <img src="./assets/imgs/logo.png" alt="site-logo" style={{ width: "190px", height: "170px" }} />
                </div>
                <div className="description-text">
                  <div className="text-wrapper">
                    <p className="text">Akhilesh Pandey Construction Company brings over 19 years of local and national construction experience; with a reputation for delivering large and unique design-build projects ahead of schedule and on budget.</p>
                  </div>
                </div>
              </div>
              <div className="footer-widget-box">
                <h2 className="title">Contact US</h2>
                <div className="contact-meta">
                  <div className="contact-item">
                    <span className="icon"><i className="fa-solid fa-location-dot" /></span>
                    <span className="text">Vill. Khutha, Post Baheliyabhat, Distt, Satna (M.P.)</span>
                  </div>
                  <div className="contact-item">
                    <span className="icon"><i className="fa-solid fa-envelope" /></span>
                    <span className="text"><a href="mailto:akhileshpandeyjp88@gmail.com">akhileshpandeyjp88@gmail.com</a></span>
                  </div>
                  <div className="contact-item">
                    <span className="icon"><i className="fa-solid fa-phone" /></span>
                    <span className="text"><a href="tel:+918819883888">+91 8819883888</a></span>
                    <span className="text"><a href="tel:+918982881988">+91 8982881988</a></span>
                  </div>
                </div>
              </div>
              <div className="footer-widget-box">
                <h2 className="title">About Company</h2>
                <ul className="footer-nav-list">
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/about-us">About Us</Link></li>
                  <li><Link to="/project">Project</Link></li>

                </ul>
              </div>
              <div className="footer-widget-box">
                <h2 className="title">Services</h2>
                <ul className="footer-nav-list">
                  <li><Link to="/services">Services</Link></li>
                  {/* <li><a to="#">Blog</a></li> */}
                  <li><Link to="/gallery">Gallery</Link></li>
                  <li><Link to="/contact-us">Contact Us</Link></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copyright-area-inner">
            <div className="copyright-text">
              <p className="text">© <a href="tel:+918982471422">2024 AI Construction | Designed by Web solution & digital marketing</a> </p>
            </div>
            <ul className="nav-links">
              <li><a to="#"></a></li>
              <li><a to="#"></a></li>
              <li><a to="#"></a></li>
            </ul>
          </div>
        </div>
      </footer>




      <WhatsappIcon />

    </>
  )
}

export default Footer