import React from 'react'
import { Link } from "react-router-dom";


const AboutUs = () => {
  return (
    <>
      <section className="page-title-area">
        <div className="container">
          <div className="page-title-area-inner section-spacing">
            <div className="area-shape-1">
              <img src="assets/imgs/shape/Contact.png" alt="shape" />
            </div>
            <div className="section-content">
              <div className="section-title-wrapper">
                <div className="title-wrapper">
                  <h1 className="section-title wow fadeInUp" data-wow-delay=".3s">About Us</h1>
                </div>
              </div>
              <ul className="breadcrumb wow fadeInUp" data-wow-delay=".5s">
                <li><Link to="/">Home</Link></li>
                <li>About Us</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area">
        <div className="container">
          <div className="about-area-inner section-spacing-top">
            <div className="section-header wow fadeInUp" data-wow-delay=".3s">
              <div className="section-title-wrapper">
                <div className="subtitle-wrapper">
                  <span className="section-subtitle">about us</span>
                </div>
                <div className="title-wrapper">
                  <h2 className="section-title" style={{ fontSize: "32px" }}>We, build or remodel homes, commercial buildings, infrastructure and industrial projects</h2>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="thumb wow fadeInLeft" data-wow-delay=".3s">
                <img src="assets/imgs/gallery/gallery-1.webp" alt="gallery image" />
              </div>
              <div className="content-last wow fadeInRight" data-wow-delay=".5s">
                <div className="text-wrapper">
                  <p className="text" style={{ textAlign: "justify" }}>Akhilesh Pandey Construction Company brings over 19 years of local and national construction experience; with a reputation for delivering large and unique design-build projects ahead of schedule and on budget. Our company has served clients and building owners for over four decades. In that time, we have found that the key to every project’s success is understanding the owner’s needs and expectations and then exceeding those expectations .</p>
                  {/* <p className="text">AI Construction is a trusted name in the construction industry with an established reputation for high quality standards, design uniqueness and transparency.</p> */}
                </div>
                <div className="feature-list">
                  <ul>
                    <li><i className="fa-solid fa-angles-right" />Over 19 Years of Experience
                    </li>
                    <li><i className="fa-solid fa-angles-right" />On-time Delivery of Projects
                    </li>
                    <li><i className="fa-solid fa-angles-right" />Clear & Transparent Pricing
                    </li>
                    <li><i className="fa-solid fa-angles-right" />Excellent Customer Support

                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="counter-area">
        <div className="container">
          <div className="counter-area-inner section-spacing-top">
            <div className="counter-wrapper-box">
              <div className="counter-wrapper">
                <div className="counter-box  wow fadeInUp" data-wow-delay=".3s">
                  <div className="icon">
                    <img src="assets/imgs/icon/icon-7.webp" alt="icon" />
                  </div>
                  <div className="content">
                    <h2 className="number t-counter">3k</h2>
                    <p className="text">Happy Clients</p>
                  </div>
                </div>
                <div className="counter-box  wow fadeInUp" data-wow-delay=".5s">
                  <div className="icon">
                    <img src="assets/imgs/icon/icon-8.webp" alt="icon" />
                  </div>
                  <div className="content">
                    <h2 className="number t-counter">2k</h2>
                    <p className="text">Complete Project</p>
                  </div>
                </div>
                <div className="counter-box  wow fadeInUp" data-wow-delay=".7s">
                  <div className="icon">
                    <img src="assets/imgs/icon/icon-9.webp" alt="icon" />
                  </div>
                  <div className="content">
                    <h2 className="number t-counter">75+</h2>
                    <p className="text">Team Member</p>
                  </div>
                </div>
                <div className="counter-box  wow fadeInUp" data-wow-delay=".9s">
                  <div className="icon">
                    <img src="assets/imgs/icon/icon-10.webp" alt="icon" />
                  </div>
                  <div className="content">
                    <h2 className="number t-counter">12+</h2>
                    <p className="text">Winning Award</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq-area">
        <div className="container">
          <div className="faq-area-inner section-spacing">
            <div className="section-content wow fadeInLeft" data-wow-delay=".3s">
              <div className="section-title-wrapper">
                <div className="title-wrapper">
                  <h2 className="section-title" style={{ fontSize: "37px" }}>Akhilesh Pandey (Director)</h2>
                </div>
              </div>
              <div className="accordion-faq">
                <div className="accordion accordion-flush" id="accordionFlushExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                        Over 19 Years of Experience
                      </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body" style={{ textAlign: "" }}>We specialize in Civil & Structural Building Works, Residential Construction,Building Contractor, Interior & Furnishing works and also, our sheer interest lies in developing commercial and residential spaces at an affordable price without compromising on the quality.
                        We Have been into this business for more than 19 Years and over this passage of time, we keep up Delivering Quality & Excellence in our Works.
                        Ours is a team of highly professional individuals who believe quality is fundamental and time is precious.
                        The objective of the company is to bring the best construction techniques & technical innovations to our projects.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="faq-thumb wow fadeInRight" data-wow-delay=".5s">
              <img src="assets/imgs/gallery/Director.jpeg" alt="image" />
            </div>
          </div>
        </div>
      </section>



      <section className="faq-area">
        <div className="container">
          <div className="faq-area-inner section-spacing">

          <div className="faq-thumb wow fadeInRight" data-wow-delay=".5s">
              <img src="assets/imgs/gallery/CEO.jpeg" alt="image" />
            </div>
            <div className="section-content wow fadeInLeft" data-wow-delay=".3s">
              <div className="section-title-wrapper">
                <div className="title-wrapper">
                  <h2 className="section-title" style={{ fontSize: "37px" }}>Indrabhan Pandey (CEO)</h2>
                </div>
              </div>
              <div className="accordion-faq">
                <div className="accordion accordion-flush" id="accordionFlushExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="true" aria-controls="flush-collapseOne">
                        Over 19 Years of Experience
                      </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body" style={{ textAlign: "" }}>We specialize in Civil & Structural Building Works, Residential Construction,Building Contractor, Interior & Furnishing works and also, our sheer interest lies in developing commercial and residential spaces at an affordable price without compromising on the quality.
                        We Have been into this business for more than 19 Years and over this passage of time, we keep up Delivering Quality & Excellence in our Works.
                        Ours is a team of highly professional individuals who believe quality is fundamental and time is precious.
                        The objective of the company is to bring the best construction techniques & technical innovations to our projects.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </section>


      <section className="service-area-2">
        <div className="container">
          <div className="service-area-2-inner section-spacing-top">
            <div className="services-wrapper-box mt-0">
              <div className="services-wrapper">
                <a to="service-details.html">
                  <div className="service-box wow fadeInUp" data-wow-delay=".3s">
                    <div className="icon">
                      <img src="assets/imgs/icon/icon-13.webp" alt="service icon" />
                    </div>
                    <div className="content">
                      <h3 className="title"><b> Our Values </b> </h3>
                      <p className="text">

                        <ul>
                          <li> <b>1.</b> To enhance the quality of constructions.</li>
                          <li>  <b>2.</b> To serve with best value for money and high return on project. </li>
                          <li>  <b>3.</b> To acquaint client with Residential and commercial Project of standards. </li>
                          <li>  <b>4.</b> Serve with an environment of professionalism ,team work and excellence . </li>
                          <li>   <b>5.</b> To respect all environment rules and regulation and legal requirements.</li>
                          <li> <b>6.</b> To enhance customers  </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </a>
                <a to="service-details.html">
                  <div className="service-box wow fadeInUp" data-wow-delay=".5s">
                    <div className="icon">
                      <img src="assets/imgs/icon/icon-14.webp" alt="service icon" />
                    </div>
                    <div className="content">
                      <h3 className="title"><b>Security & Safety</b> </h3>
                      <p className="text">The manpower of Akhilesh Pandey  is its most valuable resource. To ensure t safe workplace for all stuffs and also clients ‘ investment and facilities, the group has evolved a strong health and safety culture driven by a goal to achieve “Zero accidents“ and “zero man-hours lost”. The safety culture has been instilled in ours all employees with the conviction  that every accident is preventable. The company fully acknowledges that by complying with stringent health, safety and Environment  procedure ,zero accidents can be accomplished.
                      </p>
                      <br />
                      <br />
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq-area">
        <div className="container">
          <div className="faq-area-inner section-spacing">
            <div className="section-content wow fadeInLeft" data-wow-delay=".3s">
              <div className="section-title-wrapper">
                <div className="title-wrapper">
                  <h2 className="section-title">Our Work History</h2>
                </div>
              </div>
              <div className="accordion-faq">
                <div className="accordion accordion-flush" id="accordionFlushExample">

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        2001
                      </button>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body"><ul><li><b>1. </b> Undertook first entrepreneurial work as contractor in Madhya Pradesh Guna under the Jaypee group for construction of Jaypee university of Engineering And Technology set up at Raghogarh, Guna. </li>
                        <li><b>2. </b> Numbers of manpower = 30</li>
                      </ul>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        2004
                      </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">It is a long established fact that a reader will be distr
                        <ul>
                          <li>
                            <b>2.</b>  Undertook second work as contractor in Tanda Uttar Pradesh under the Jaypee Ayodhya Grinding Operation, Tanda Uttar Pradesh for construction of Mini Cement Plant. The cement grinding mill at this unit, which is a vertical roller mill, consumes substantially less power than ball mill.
                          </li>
                          <li><b>2. </b>Numbers of manpower = 60
                          </li>
                          <li> <b> 3. </b> In same year JP group offered us for construction of Hydel Power Project in Himanchal Pradesh we undertook as third work as contractor in Himanchal Pradesh.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        2008
                      </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                      <div className="accordion-body">
                        <ul>
                          <li> <b> 1. </b>Undertook fourth work as contractor in Baghwar Madhya Pradesh under the JP Group of JP Cement Plant for the construction of cement plant. In this work we had completed Row Mill, and other plant relative work.</li>
                          <li> <b>2. </b>Work Duration = 3 years
                          </li>
                          <li> <b>3. </b>Numbers of Manpower = 85
                          </li>
                          <li> <b>4.</b>Undertook fifth work as contractor in Dalla Uttar Pradesh under the JP Group of cement Plant for the construction of cement plant. In this work we had completed many big works plant related.</li>
                          <li> <b>5. </b> Work Duration = 2 years
                          </li>
                          <li> <b>6. </b> Numbers of Manpower = 81
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-faq">
              <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      2011
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body"><ul>
                      <li><b>1. </b>Undertook sixth work as  contractor in Nigri Madhya Pradesh under the JP Group of JP Cement Plant for the construction of cement plant. In this work also we had completed many big works related plant. From started till end we had completed all the types of works. Under the guardians of Vice President of JP Group.
                      </li>
                      <li><b>2. </b> Work Duration = 4 years</li>
                      <li><b>3. </b> Number of manpower = 90</li>
                    </ul></div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      2016
                    </button>
                  </h2>
                  <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <ul>
                        <li><b>1. </b>Undertook seventh work as contractor in Bhutan. In this project we had completed Reinforcement, Shuttering and Concreating work and also other Miscellaneous Civil works in Underground of machine hall cavern/transformer hall and surge shaft of Power house complex.</li>
                        <li><b>2. </b> Work Duration = 2 years </li>
                        <li><b>3. </b> Number of Manpower = 105</li>
                        <li><b>4. </b> Undertook eighth work as contractor in Salimnabad katni Madhya Pradesh. In this Project we had completed all the works regarding under ground canal. Work Duration = 2 years, Number of manpower = 80</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      2018
                    </button>
                  </h2>
                  <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <b>1. </b>Undertook nineth work as contractor in Maihar Madhya Pradesh. Hiring of Manpower Civil work for Reliance cement company Pvt. Ltd. Maihar
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




    </>
  )
}

export default AboutUs