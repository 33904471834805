import React from 'react'
import { Link } from "react-router-dom";
import SliderTestimonials from '../../component/slidercomponent/SliderTestimonials';

const Testimonials = () => {
    return (
        <>

            {/* <section className="project-area-2">
                <div className="container">
                    <div className="project-area-2-inner section-spacing">
                        <div className="section-header wow fadeInUp" data-wow-delay=".3s">
                            <div className="section-title-wrapper">
                                <div className="subtitle-wrapper">
                                    <span className="section-subtitle">AI Construction</span>
                                </div>
                                <div className="title-wrapper">
                                    <h2 className="section-title">Testimonials
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="testimonial-area" style={{ backgroundColor: "white" }}>
            <section className="project-area-2">
                <div className="container">
                    <div className="project-area-2-inner section-spacing">
                        <div className="section-header wow fadeInUp" data-wow-delay=".3s">
                            <div className="section-title-wrapper">
                                <div className="subtitle-wrapper">
                                    <span className="section-subtitle">AI Construction</span>
                                </div>
                                <div className="title-wrapper">
                                    <h2 className="section-title">Testimonials
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                <div className="container">
                    <div className="testimonial-area-inner section-spacing-top">
                        <div className="testimonial-wrapper-box wow fadeInUp">
                            <div className="testimonial-wrapper">
                                <div className=" testimonial-slider">
                                    <div className="">
                                        <SliderTestimonials />
                                    </div>
                                </div>
                                {/* <div className="nav-wrapper">
                                    <div className="testimonial-button-prev">
                                        <i className="fa-solid fa-arrow-left" />
                                    </div>
                                    <div className="testimonial-button-next">
                                        <i className="fa-solid fa-arrow-right" />
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="pagination-wrapper d-lg-none">
                                <div className="testimonial-pagination" />
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Testimonials