import React, { useState } from 'react'
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";



const Contact = () => {

  const [name, setName] = useState("");
  const [mail, setmail] = useState("");
  const [massages, setMassages] = useState("");
  const [number, setNumber] = useState("");
  const [state, setState] = useState("");
  const [subject, setSubject] = useState("");
  const [city, setCity] = useState("");

  const nameChange = (e) => {
    const value = e.target.value;
    setName(value);
  };
  const stateChange = (e) => {
    const value = e.target.value;
    setState(value);
  };
  const cityChange = (e) => {
    const value = e.target.value;
    setCity(value);
  };
  const subjectChange = (e) => {
    const value = e.target.value;
    setSubject(value);
  };
  const numberChange = (e) => {
    const value = e.target.value;
    setNumber(value);
  };

  const mailChange = (e) => {
    const value = e.target.value;
    setmail(value);
  };
  const massagesChange = (e) => {
    const value = e.target.value;
    setMassages(value);
  };


  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(

        "service_g64mytg",
        "template_281srbf",
        "#myForm",
        "fpq6WzMGTknIEGaq7"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          if (response.text === "OK") {
            setName("");
            setmail("");
            setMassages("");
            setNumber("");
            setState("");
            setSubject("");
            setCity("");

          }
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <>
      <div>
        <section className="page-title-area">
          <div className="container">
            <div className="page-title-area-inner section-spacing">
              <div className="area-shape-1">
                <img src="assets/imgs/shape/Contact.png" alt="shape" />
              </div>
              <div className="section-content">
                <div className="section-title-wrapper">
                  <div className="title-wrapper">
                    <h1 className="section-title wow fadeInUp" data-wow-delay=".3s">Contact</h1>
                  </div>
                </div>
                <ul className="breadcrumb wow fadeInUp" data-wow-delay=".5s">
                  <li><Link to="/">Home</Link></li>
                  <li>Contact</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-area">
          <div className="container">
            <div className="contact-area-inner section-spacing-top">
              <div className="contact-box wow fadeInUp" data-wow-delay=".3s">
                <div className="form-area">
                  <div className="section-title-wrapper">
                    <div className="title-wrapper">
                      <h2 className="section-title">Send message</h2>
                    </div>
                  </div>
                  <div className="contact-wrap">
                    <form method="POST" onSubmit={sendEmail} id="myForm">
                      <div className="contact-formwrap">
                        <div className="contact-formfield name">
                          <input type="text" id="Name" placeholder="Your name"
                            name="from_name"
                            onChange={(e) => nameChange(e)}
                            required
                            value={name}
                          />
                        </div>
                        <div className="contact-formfield">
                          <input type="text" id="Email" placeholder="Email"
                            name='email'
                            onChange={(e) => mailChange(e)}
                            value={mail}
                            required
                          />
                        </div>
                        <div className="contact-formfield">
                          <input type="text" id="Phone" placeholder="Phone"
                            name='number'
                            onChange={(e) => numberChange(e)}
                            value={number}
                            required
                          />
                        </div>
                        <div className="contact-formfield messages">
                          <textarea id="Messages" placeholder="Messages"
                            onChange={(e) => massagesChange(e)}
                            name='subject'
                            value={massages}
                            required
                          />
                        </div>
                      </div>
                      {/* <a className="contctbtnarea_sloutr" href="">
                      <div className="submit-btn">
                        <button type="submit" className="t-btn t-btn-primary btn-text-flip bg-active"><span>Send Message</span><i className="fa-solid fa-arrow-right" /></button>
                      </div></a> */}

                      <a className="" href="" style={{textAlign:"center"}}>
                        <div className="submit-btn" style={{textAlign:"center"}}>
                          <input type="submit" name className="mtnsbmt_design" style={{textAlign:"center"}}/>
                        </div>
                      </a>
                    </form>
                  </div>
                </div>
                <div className="form-thumb">
                  <img src="assets/imgs/gallery/gallery-6.webp" alt="image" />
                </div>
              </div>
              <div className="contact-meta-box">
                <div className="contact-meta">
                  <div className="contact-item wow fadeInUp" data-wow-delay=".3s">
                    <div className="icon">
                      <i className="fa-solid fa-phone" />
                    </div>
                    <div className="content">
                      <h3 className="title"> Number</h3>
                      <p className="text"><a href="tel:+918819883888">+91 8819883888</a></p>
                      <p className="text"><a href="tel:+918982881988">+91 8982881988</a></p>
                    </div>
                  </div>
                  <div className="contact-item wow fadeInUp" data-wow-delay=".5s">
                    <div className="icon">
                      <i className="fa-solid fa-envelope" />
                    </div>
                    <div className="content">
                      <h3 className="title">Email Address</h3>
                      <p className="text"><a href="mailto:akhileshpandeyjp88@gmail.com">akhileshpandeyjp88@gmail.com</a></p>
                    </div>
                  </div>
                  <div className="contact-item wow fadeInUp" data-wow-delay=".7s">
                    <div className="icon">
                      <i className="fa-solid fa-location-dot" />
                    </div>
                    <div className="content">
                      <h3 className="title">Address</h3>
                      <p className="text">Vill. Khutha, Post Baheliyabhat, Distt, Satna (M.P.)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* map area start  */}
        <div className="map-area">
          <div className="container">
            <div className="map-area-inner section-spacing">
              <div className="map-box wow fadeInUp" data-wow-delay=".3s">
                <div style={{ overflow: 'hidden', resize: 'none', maxWidth: '100%', width: 1200, height: 400 }}><div id="my-map-display" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=+Vill.+Khutha,+Post+Baheliyabhat,+Distt,+Satna+(M.P.)&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="my-codefor-googlemap" rel="nofollow" href="https://www.bootstrapskins.com/themes" id="get-data-for-map">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#my-map-display img.text-marker{max-width:none!important;background:none!important;}img{max-width:none}" }} /></div>
              </div>
            </div>
          </div>
        </div>
        {/* map area end  */}

      </div>


    </>
  )
}

export default Contact